import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import theme from "theme";
import Fonts from "theme/fonts";
import { TenantThemeProvider } from "@contexts/tenantTheme/TenantThemeProvider";
import "../theme/styles/nprogress.css";
import NProgress from "nprogress";
import Router from "next/router";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FormProvider } from "@contexts/formContext";
import { queryClientConfig } from "@lib/react-query";
import "@lib/i18n";
import GoogleMapsProvider from "@providers/GoogleMapsProvider";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const queryClient = new QueryClient(queryClientConfig);

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
    const getLayout = Component.getLayout ?? (page => page);

    return (
        <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={theme}>
                <Fonts />
                <TenantThemeProvider>
                    <GoogleMapsProvider>
                        <FormProvider>
                            {getLayout(<Component {...pageProps} />)}
                        </FormProvider>
                    </GoogleMapsProvider>
                </TenantThemeProvider>
            </ChakraProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
}

export default MyApp;
