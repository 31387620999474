import { useTranslation as useTranslationi18n } from "react-i18next";
import * as en from "../../public/Languages/en/index";
import * as es from "../../public/Languages/es/index";
import * as bg from "../../public/Languages/bg/index";
import React, { useContext, useMemo } from "react";
import { TenantThemeContext } from "@contexts/tenantTheme/TenantThemeContext";
import { DEFAULT_LOCALE } from "@constants/common";

const TRANSLATIONS = { en, es, bg };

type Count = number;

// This is because the count is optional as a second argument in the t function
type ConfigOrCount = any | Count;

export default function useTranslation(path: string = "") {
    const tenant = useContext(TenantThemeContext);

    /* get locale from tenant context */
    const locale = useMemo(
        () => tenant?.user?.languageCode || tenant?.locale || DEFAULT_LOCALE,
        [tenant]
    );

    const { t: i, i18n } = useTranslationi18n([path], {
        lng: locale,
    });

    const makeTranslation = React.useCallback(
        (keyString: string, count?: Count) => {
            const translation =
                TRANSLATIONS?.[locale?.toString()]?.[path?.toString()];
            if (!translation) return null;

            if (count !== undefined) {
                const singularKey = `${keyString}_ONE`;
                const pluralKey = `${keyString}_OTHER`;
                const selectedKey = count === 1 ? singularKey : pluralKey;

                return translation[selectedKey?.toString()] || null;
            } else {
                return translation[keyString?.toString()] || null;
            }
        },
        [locale, path]
    );

    const t = React.useCallback(
        (keyString: string, configArg?: ConfigOrCount) => {
            if (!configArg) return makeTranslation(keyString);

            const isCount = typeof configArg === "number";

            if (isCount) {
                return makeTranslation(keyString, configArg as Count);
            }

            return i(keyString, configArg);
        },
        [i, makeTranslation]
    );

    return { t, locale, i18n };
}
