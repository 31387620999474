import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    InputGroup,
    InputRightElement,
    IconButton,
    InputProps,
    Icon,
} from "@chakra-ui/react";
import useTranslation from "@hooks/useTranslation";
import { useState } from "react";
import { ErrorOption } from "react-hook-form";
import { IconType } from "react-icons";
import { FiEye, FiEyeOff } from "react-icons/fi";

interface FormInputProps extends InputProps {
    register: Function;
    error?: ErrorOption;
    label?: string;
    placeholder?: string;
    name: string;
    isRequired?: boolean;
    type: string;
    value?: string;
    isErrorMessage?: boolean;
    disabled?: boolean;
    rightElement?: boolean;
    icon?: IconType;
}

export default function FormInput({
    register,
    error,
    label,
    placeholder,
    name,
    isRequired,
    disabled,
    type = "text",
    value,
    onChange,
    onClick,
    isErrorMessage = true,
    rightElement = false,
    icon,
    ...restProps
}: FormInputProps) {
    const [show, setShow] = useState(false);
    const { t: errorT } = useTranslation("formErrors");

    const handleClick = () => setShow(!show);

    return (
        <FormControl
            isDisabled={disabled}
            isInvalid={!!error}
            isRequired={isRequired}
        >
            {label && (
                <FormLabel letterSpacing={0.5} htmlFor={name} mb={0.5}>
                    {label}
                </FormLabel>
            )}
            <InputGroup onChange={onChange} onClick={onClick}>
                <Input
                    value={value}
                    {...register(name)}
                    type={type === "password" ? (show ? "text" : type) : type}
                    placeholder={placeholder}
                    size="sm"
                    name={name}
                    h="31.5px" // NO TOCAR
                    border="1px"
                    borderColor="gray.200"
                    borderRadius="md"
                    id={name}
                    letterSpacing={0.5}
                    key={name}
                    step=".001"
                    {...restProps}
                />
                {type === "password" && (
                    <InputRightElement>
                        <IconButton
                            aria-label="View password"
                            variant="unstyled"
                            onClick={handleClick}
                            size="sm"
                            my="auto"
                            icon={
                                show ? (
                                    <Icon as={FiEyeOff} />
                                ) : (
                                    <Icon as={FiEye} />
                                )
                            }
                        />
                    </InputRightElement>
                )}
                {rightElement && (
                    <InputRightElement>
                        <Icon as={icon} mt={2} mr={1.5} boxSize={4} />
                    </InputRightElement>
                )}
            </InputGroup>
            {isErrorMessage && (
                <FormErrorMessage
                    pt={1}
                    fontSize="xxs"
                    letterSpacing={0.5}
                    fontWeight="semibold"
                >
                    {errorT(error?.message) || error?.message}
                </FormErrorMessage>
            )}
        </FormControl>
    );
}
