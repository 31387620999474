import { ModuleKey } from "@interfaces/common";

export const EMPTY_FIELD = "-";

export const TENANT_THEME = "tenantTheme";

export const PERSON_STATUS_INACTIVE = "inactive";
export const PERSON_STATUS_ACTIVE = "active";
export const PERSON_STATUS_PRESUMED = "presumed";

export const USER_STATUS_INACTIVE = 0;
export const USER_STATUS_ACTIVE = 1;
export const USER_STATUS_PRESUMED = -1;

export const LEGACY_PERSON_TYPE_INDIVIDUAL = 1;
export const LEGACY_PERSON_TYPE_COMPANY = 2;

export const PERSON_TYPE_INDIVIDUAL = "individual";
export const PERSON_TYPE_COMPANY = "legal";

export const personTypes = {
    [PERSON_TYPE_INDIVIDUAL]: "INDIVIDUAL_PERSON",
    [PERSON_TYPE_COMPANY]: "LEGAL_ENTITY",
};

export const ACTIVE = 1;
export const INACTIVE = 0;
export const INREVIEW = 3;

export const VALID = "valid";
export const INVALID = "invalid";

export const clientStatusOptions = {
    [ACTIVE]: "Active",
    [INACTIVE]: "Inactive",
    [INREVIEW]: "In Review",
};

export const personStatusOptions = {
    [PERSON_STATUS_ACTIVE]: "Active",
    [PERSON_STATUS_INACTIVE]: "Inactive",
    [PERSON_STATUS_PRESUMED]: "Presumed",
};

export const CONTACT_EMAIL_TYPE = "email";
export const CONTACT_PHONE_TYPE = "phone";

export const PRE = "pre";
export const POST = "post";

export const receiptInsurersTableOptions = {
    [PRE]: "Pre",
    [POST]: "Post",
};

export const QUESTIONNAIRE_TEMPLATE = "template-questionnaire";

export const SUBSCRIBED = "1";
export const UNSUBSCRIBED = "0";

export const SUBSCRIBE = "subscribe";
export const UNSUBSCRIBE = "unsubscribe";

export const subscriptionStatusOptions = {
    [SUBSCRIBED]: "SUBSCRIBED",
    [UNSUBSCRIBED]: "UNSUBSCRIBED",
};
//User profile constants
export const PROFILE_ADMIN = 0;
export const PROFILE_EMPLOYEE = 1;
export const PROFILE_MANAGER = 2;
export const PROFILE_BROKER = 3;
export const PROFILE_TPA = 4;
export const PROFILE_CLIENT = 5;
export const PROFILE_INSURER = 6;

export const TYPE_INSURER = 1;
export const TYPE_BROKER = 2;
export const TYPE_SALE_GROUP = 3;
export const TYPE_SALE_POINT = 4;
export const TYPE_REINSURANCE_BROKER = 5;
export const TYPE_CEDENT_INSURER = 6;
export const TYPE_RETRO_INSURER = 7;
export const TYPE_MANAGER = 8;

export const childReceiptsTypeOptions = {
    [TYPE_INSURER]: "Insurer",
    [TYPE_BROKER]: "Broker",
    [TYPE_SALE_GROUP]: "Sale Group",
    [TYPE_SALE_POINT]: "Sale Point",
    [TYPE_REINSURANCE_BROKER]: "Reinsurance Broker",
    [TYPE_CEDENT_INSURER]: "Cedent Insurer",
    [TYPE_RETRO_INSURER]: "Retro Insurer",
    [TYPE_MANAGER]: "Manager",
};

//payment frequency

export const PAYMENT_FREQUENCY_UNIQUE = 0;
export const PAYMENT_FREQUENCY_ANNUAL = 12;
export const PAYMENT_FREQUENCY_BIANNUAL = 2;
export const PAYMENT_FREQUENCY_QUADRIMESTRAL = 3;
export const PAYMENT_FREQUENCY_QUARTERLY = 4;
export const PAYMENT_FREQUENCY_MONTHLY = 1;

export const PAYMENT_FREQUENCY_OPTIONS = {
    [PAYMENT_FREQUENCY_UNIQUE]: "UNIQUE",
    [PAYMENT_FREQUENCY_ANNUAL]: "ANNUAL",
    [PAYMENT_FREQUENCY_BIANNUAL]: "BIANNUAL",
    [PAYMENT_FREQUENCY_QUADRIMESTRAL]: "QUADRIMESTRAL",
    [PAYMENT_FREQUENCY_QUARTERLY]: "QUARTERLY",
    [PAYMENT_FREQUENCY_MONTHLY]: "MONTHLY",
};

//Legacy renewal frequency

export const LEGACY_RENEWAL_FREQUENCY_NONE = 0;
export const LEGACY_RENEWAL_FREQUENCY_ANNUAL = 1;

export const legacyRenewalFrequencyOptions = {
    [LEGACY_RENEWAL_FREQUENCY_NONE]: "None",
    [LEGACY_RENEWAL_FREQUENCY_ANNUAL]: "Annual",
};

export const LEGACY_TYPE_POLICYHOLDER = 3;
export const LEGACY_TYPE_SHAREHOLDER = 1;
export const LEGACY_TYPE_PERSONAL_GUARANTEE = 2;
export const LEGACY_TYPE_CERTIFICATE_POLICYHOLDER = 4;
export const LEGACY_TYPE_ADDITIONAL_POLICYHOLDER = 5;
export const LEGACY_TYPE_INSURED = 6;
export const LEGACY_TYPE_BENEFICIARY = 7;
export const LEGACY_TYPE_DIRECTOR = 8;
export const LEGACY_TYPE_EMPLOYEE = 9;

export const legacyPersonRelationTypeOptions = {
    [LEGACY_TYPE_SHAREHOLDER]: "Shareholder",
    [LEGACY_TYPE_PERSONAL_GUARANTEE]: "Personal Guarantee",
    [LEGACY_TYPE_POLICYHOLDER]: "Policyholder",
    [LEGACY_TYPE_CERTIFICATE_POLICYHOLDER]: "Certificate Policyholder",
    [LEGACY_TYPE_ADDITIONAL_POLICYHOLDER]: "Additional Policyholder",
    [LEGACY_TYPE_INSURED]: "Insured",
    [LEGACY_TYPE_BENEFICIARY]: "Beneficiary",
    [LEGACY_TYPE_DIRECTOR]: "Director",
    [LEGACY_TYPE_EMPLOYEE]: "Employee",
};

export const TYPE_SHAREHOLDER = "shareholder";
export const TYPE_PERSONAL_GUARANTEE = "personal-guarantee";
export const TYPE_POLICYHOLDER = "policyholder";
export const TYPE_CERTIFICATE_POLICYHOLDER = "certificate-policyholder";
export const TYPE_ADDITIONAL_POLICYHOLDER = "additional-policyholder";
export const TYPE_INSURED = "insured";
export const TYPE_BENEFICIARY = "beneficiary";
export const TYPE_DIRECTOR = "director";
export const TYPE_EMPLOYEE = "employee";
export const TYPE_DRIVER = "driver";
export const TYPE_OWNER = "owner";

export const personRelationTypeOptions = {
    [TYPE_SHAREHOLDER]: "Shareholder",
    [TYPE_PERSONAL_GUARANTEE]: "Personal Guarantee",
    [TYPE_POLICYHOLDER]: "Policyholder",
    [TYPE_CERTIFICATE_POLICYHOLDER]: "Certificate Policyholder",
    [TYPE_ADDITIONAL_POLICYHOLDER]: "Additional Policyholder",
    [TYPE_INSURED]: "Insured",
    [TYPE_BENEFICIARY]: "Beneficiary",
    [TYPE_DIRECTOR]: "Director",
    [TYPE_EMPLOYEE]: "Employee",
    [TYPE_DRIVER]: "Employee",
    [TYPE_OWNER]: "Employee",
};
export const DNI = "dni";
export const CIF = "cif";
export const NIE = "nie";
export const PASSPORT = "passport";
export const OTHER = "other";

export const documentTypes = {
    [DNI]: "DNI",
    [CIF]: "CIF",
    [NIE]: "NIE",
    [PASSPORT]: "PASSPORT",
    [OTHER]: "OTHER",
};

export const GENDER_FEMALE = "female";
export const GENDER_MALE = "male";
export const GENDER_OTHER = "other";

export const genders = {
    [GENDER_MALE]: "MALE",
    [GENDER_FEMALE]: "FEMALE",
    [GENDER_OTHER]: "OTHER",
};

export const ACCEPT = "accept";

export const REJECT = "reject";

export const APPROVE = "approve";

export const COUNTRIES = "countries";

export const TAXES = "taxes";

export const CHANGED = "change";

export const DISCOUNT = "discount";
export const FIXED_DISCOUNT = "fixed-discount";

export const SURCHARGE = "surcharge";
export const FIXED_SURCHARGE = "fixed-surcharge";

export const DISCOUNT_OVER_NET = "discount-over-net";
export const FIXED_DISCOUNT_OVER_NET = "fixed-discount-over-net";

export const SURCHARGE_OVER_NET = "surcharge-over-net";
export const FIXED_SURCHARGE_OVER_NET = "fixed-surcharge-over-net";

export const NET_PREMIUM = "net-premium";
export const TOTAL_PREMIUM = "total-premium";

export const INCREASE = "increase";

export const DECREASE = "decrease";

export const FIXED = "fixed";

export const PERCENTAGE = "percentage";

export const DEFAULT_COUNTRY = {
    name: "Spain",
    countryCode: "ES",
};

export const INSURER = "Insurer";
export const BROKER = "Broker";

export const remittanceCreationOptions = {
    [INSURER]: "INSURER",
    [BROKER]: "BROKER",
};
export const DEFAULT_LOCALE = "en";

export const DEFAULT_CURRENCY_CODE = "EUR";

export const DEFAULT_CURRENCY_LOCALE = "es-MX";

export const DEFAULT_TIMEZONE = "UTC";

export const STRING_TYPE = "string";

export const NUMBER_TYPE = "number";

export const DRIVER = "driver";
export const OWNER = "owner";
export const CAR = "car";
export const HEADER_IMAGE_CAR = "car";
export const HEADER_IMAGE_TRAVEL = "travel";
export const ERROR = "error";
export const SUCCESS = "success";
export const LOADING = "loading";
export const INFO = "info";
export const WARNING = "warning";
export const GLOBAL = "global";

export const ESP_LANGUAGE = "es";

//Modules
export const MODULE_POLICY: ModuleKey = { base: "policy", plural: "policies" };
export const MODULE_RECEIPT: ModuleKey = {
    base: "receipt",
    plural: "receipts",
};
export const MODULE_OPPORTUNITY: ModuleKey = {
    base: "opportunity",
    plural: "quotes",
};
export const MODULE_BROKER: ModuleKey = {
    base: "network",
    plural: "brokers",
};

export const MODULE_COMMITTEE_PROPOSALS: ModuleKey = {
    base: "",
    plural: "committee-proposals",
};
export const MODULE_USER: ModuleKey = {
    base: "user",
    plural: "users",
};
export const MODULE_CLAIM: ModuleKey = { base: "claim", plural: "claims" };
export const MODULE_FORMALITY: ModuleKey = {
    base: "claim",
    plural: "formalities",
};

export const MODULE_INSURER: ModuleKey = {
    base: "network",
    plural: "insurers",
};

export const MODULE_DOCUMENTS_SETTINGS: ModuleKey = {
    base: "settings",
    plural: "settings",
};

export const FOLDER_ROOT = "root";
export const CONTACT = "contact";
export const ADDRESS = "address";
export const PERSON = "person";
export const SUMMARY = "summary";
export const USER = "user";

export const OFFICES = "offices";
export const CONTACTS = "contacts";

// File types
export const ZIP = "application/zip";
export const PDF = "application/pdf";
export const EXCEL = "application/vnd.ms-excel";

export const OPPORTUNITY = "opportunity";

export const SPAIN_COUNTRY_CODE = "ES";

export const USA_COUNTRY_CODE = "US";

export const documentExemptionConditions = [
    {
        countryCode: USA_COUNTRY_CODE,
        documentTypes: [OTHER],
    },
];

export const defaultLegalDocumentTypes = [
    {
        country: SPAIN_COUNTRY_CODE,
        documentType: CIF,
    },
];

export const CLAIM_PAYMENT_FORM = "pendingFundMovements";
export type CollectIdsFunctionT = () => {
    checkIds: string[];
    uncheckIds: string[];
};

export const RESPONSIBLE = "responsible";

export const ITEMS_PER_PAGE_OPTIONS = [5, 10, 20, 30, 40, 50];
